import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '250px'
  }
}))
