import {Typography, Box, Select, MenuItem, TextField, Button, Paper, Checkbox} from '@mui/material'
import {Delete, KeyboardArrowDown as KeyboardArrowDownIcon} from '@mui/icons-material'
import {useTranslation} from 'react-i18next'
import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'

import {useMemo} from 'react'

import {OrderChangeConfigOptions, OrderChangeFieldSetting} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeFieldSettingsTable.styles'

type Props = {
  fieldSettings: OrderChangeFieldSetting[]
  filterOptionsByCountry: string[]
  configOptions: OrderChangeConfigOptions
  handleAddField: (selectedItem: string) => void
  handleDeleteField: (itemId: string) => void
  handleFieldChange: (index: number, field: string, value: string | boolean) => void
}

export const OrderChangeFieldSettingsTable = ({
  fieldSettings,
  filterOptionsByCountry,
  configOptions,
  handleAddField,
  handleDeleteField,
  handleFieldChange
}: Props) => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const sortedFieldSettings = useMemo(() => {
    if (!fieldSettings || !configOptions) return []
    return fieldSettings.sort(
      (a, b) =>
        configOptions.availableFields[a.fieldName].sortKey -
        configOptions.availableFields[b.fieldName].sortKey
    )
  }, [configOptions, fieldSettings])

  const availableFields = useMemo(() => {
    if (!fieldSettings || !filterOptionsByCountry) return []
    const usedFieldNames = fieldSettings.map((setting) => setting.fieldName)
    return filterOptionsByCountry.filter((fieldName) => !usedFieldNames.includes(fieldName))
  }, [fieldSettings, filterOptionsByCountry])

  const columns = [
    {
      field: 'fieldName',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.fieldName')
    },
    {
      field: 'editLeadTime',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.editLeadTime')
    },
    {
      field: 'leadTimeUom',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.leadTimeUom')
    },
    {
      field: 'requestsPendingApprovalPermitted',
      headerName: t('configurations.hconnect.orderChange.configurationDetails.manualApproveFlag')
    },
    {field: 'actions', headerName: ''}
  ]

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {t('configurations.hconnect.orderChange.configurationDetails.fieldSettings.title')}
      </Typography>
      <Box className={classes.addFieldBox}>
        <Select
          value=""
          IconComponent={KeyboardArrowDownIcon}
          onChange={(e) => handleAddField(e.target.value)}
          displayEmpty
          inputProps={{'aria-label': 'Without label'}}
          className={classes.select}
          data-test-id="order-change-field-settings-add-field"
        >
          <MenuItem value="" disabled className={classes.selectMenu}>
            {t('configurations.hconnect.orderChange.configurationDetails.fieldSettings.addField')}
          </MenuItem>
          {availableFields.map((key) => (
            <MenuItem key={key} value={key} className={classes.selectMenu}>
              {configOptions.availableFields[key].fieldName}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Paper>
        <ResponsiveTable
          columns={columns}
          rows={sortedFieldSettings.map((item, index) => ({
            fieldName: configOptions.availableFields[item.fieldName].fieldName,
            editLeadTime: (
              <TextField
                type="number"
                value={item.editLeadTime}
                onChange={(e) => {
                  const value = e.target.value
                  if (Number(value) >= 0) {
                    handleFieldChange(index, 'editLeadTime', value)
                  }
                }}
                className={classes.select}
                data-test-id={`order-change-field-settings-edit-lead-time-${item.fieldName}`}
              />
            ),
            leadTimeUom: (
              <Select
                value={item.editLeadTimeUom}
                onChange={(e) => handleFieldChange(index, 'editLeadTimeUom', e.target.value)}
                IconComponent={KeyboardArrowDownIcon}
                className={classes.select}
                data-test-id={`order-change-field-settings-edit-lead-time-uom-${item.fieldName}`}
              >
                {configOptions.leadTimeUnitOfMeasures.map((uom) => (
                  <MenuItem key={uom} value={uom} className={classes.selectMenu}>
                    {t(`configurations.hconnect.orderChange.configurationDetails.uom.${uom}`)}
                  </MenuItem>
                ))}
              </Select>
            ),
            requestsPendingApprovalPermitted: (
              <Checkbox
                checked={item.requestsPendingApprovalPermitted}
                onChange={(e) => {
                  return handleFieldChange(
                    index,
                    'requestsPendingApprovalPermitted',
                    e.target.checked
                  )
                }}
                color="primary"
                data-test-id={`order-change-field-settings-manual-approve-flag-${item.fieldName}`}
              />
            ),
            actions: (
              <Button
                variant="text"
                onClick={() => handleDeleteField(item.fieldName)}
                className={classes.deleteButton}
              >
                <Delete />
              </Button>
            )
          }))}
          keyField="fieldName"
          onSort={() => {}}
          isMobile={isMobile}
          data-test-id="order-change-field-settings-table"
        />
      </Paper>
    </Box>
  )
}
