import {Product} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Button} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {api} from '../../../App.store'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'

interface UseInviteUserResult {
  inviteUser: (product: Product) => Promise<void>
}

export const useInviteUser = (
  userId: string | undefined,
  refetchUser: () => void
): UseInviteUserResult => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const mutation = useMutation<AxiosResponse, Error, Product, unknown>(
    async (product: Product) => {
      if (!userId) {
        throw new Error('User ID is undefined')
      }
      return await api.post('/users/invite', {
        user_id: userId,
        product: product
      })
    },
    {
      onSuccess: (data) => {
        refetchUser()
        trackEvent('cscQuickPreviewInvitationSuccess', {
          userId: loggedInUserProfile?.user_id,
          product: 'adminConsole',
          previewedUserId: userId,
          successData: JSON.stringify(data.data),
          payload: JSON.stringify(data.config.data)
        })
        enqueueSnackbar(t('quickPreview.products.form.successInvitationMsg'), {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'success',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        })
      },
      onError: (error) => {
        trackEvent('cscQuickPreviewInvitationError', {
          userId: loggedInUserProfile?.user_id,
          product: 'adminConsole',
          previewedUserId: userId,
          error: JSON.stringify(error)
        })
        enqueueSnackbar(`Error inviting user: ${error}`, {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'success',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        })
      }
    }
  )

  return {
    inviteUser: async (product: Product): Promise<void> => {
      await mutation.mutateAsync(product)
    }
  }
}
