import {
  Content,
  LoadingButton,
  Page,
  TitleNav,
  useBreakPoints,
  useTranslation
} from '@hconnect/uikit'
import {ArrowBackIos, ArrowForward} from '@mui/icons-material'
import {Box, Grid, Paper, Typography, SelectChangeEvent} from '@mui/material'
import {useFormContext} from 'react-hook-form'
import {useHistory} from 'react-router'

import {useAllowedHConnectConfigCountries} from '../../hooks/useAllowedHConnectConfigCountries'
import {OrderChangeConfig, OrderChangeConfigOptions} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeConfigurationDetails.styles'
import {OrderChangeFieldSettingsTable} from './OrderChangeFieldSettingsTable'
import {OrderChangeFormFields} from './OrderChangeFormFields'

type Props = {
  mode: 'edit' | 'create' | 'copy'
  configId?: string
  onSaveButtonClick: () => void
  configOptions: OrderChangeConfigOptions
  isSubmitting: boolean
}

export const OrderChangeConfigurationDetails = ({
  mode,
  onSaveButtonClick,
  configOptions,
  isSubmitting
}: Props) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {classes} = useStyles({isMobile})
  const history = useHistory()

  const configurableHConnectCountries = useAllowedHConnectConfigCountries()

  const formMethods = useFormContext<OrderChangeConfig>()

  const formatTitle = () => {
    switch (mode) {
      case 'edit':
        return t('configurations.hconnect.orderChange.configurationDetails.titleEdit')
      case 'create':
        return t('configurations.hconnect.orderChange.configurationDetails.titleCreate')
      case 'copy':
        return t('configurations.hconnect.orderChange.configurationDetails.titleCopy')
    }
  }

  const filterOptionsByCountry = (countryCode: string) => {
    return Object.keys(configOptions.availableFields).filter((key) =>
      configOptions.availableFields[key].allowedCountries.includes(countryCode)
    )
  }

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const countryCode = event.target.value
    const allowedFields = filterOptionsByCountry(countryCode)

    formMethods.setValue('countryCode', countryCode)
    formMethods.setValue(
      'fieldSettings',
      formMethods
        .getValues('fieldSettings')
        .filter((item) => allowedFields.includes(item.fieldName))
    )
  }

  const handleAddField = (selectedItem: string) => {
    const fieldSettings = formMethods.getValues('fieldSettings')
    formMethods.setValue('fieldSettings', [
      ...fieldSettings,
      {
        fieldName: selectedItem,
        editLeadTime: 0,
        editLeadTimeUom: configOptions.leadTimeUnitOfMeasures[0],
        requestsPendingApprovalPermitted: false,
        orderChangeSettingId: ''
      }
    ])
  }

  const handleDeleteField = (itemId: string) => {
    const fieldSettings = formMethods.getValues('fieldSettings')
    formMethods.setValue(
      'fieldSettings',
      fieldSettings.filter((item) => item.fieldName !== itemId)
    )
  }

  const handleFieldChange = (index: number, field: string, value: string | boolean) => {
    const fieldSettings = formMethods.getValues('fieldSettings')
    fieldSettings[index][field] = field === 'editLeadTime' ? Number(value) : value
    formMethods.setValue('fieldSettings', fieldSettings)
  }

  const fieldSettings = formMethods.watch('fieldSettings')
  const countryCode = formMethods.watch('countryCode')

  if (!configOptions || !fieldSettings) {
    return <div>{t('configurations.hconnect.orderChange.configurationDetails.loading')}</div>
  }

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        title={!isTablet && <Box className={classes.pageTitle}>{formatTitle()}</Box>}
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={
                isTablet
                  ? formatTitle()
                  : t('configurations.hconnect.orderChange.configurationDetails.goBack')
              }
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography data-test-id={`order-change-configuration-title-${mode}`}>
                      {t('configurations.hconnect.orderChange.configurationDetails.goBack')}
                    </Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
          </Box>
        }
      >
        <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderChangeFormFields
                handleCountryChange={handleCountryChange}
                countries={configurableHConnectCountries}
                disabled={mode === 'edit'}
              />
            </Paper>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12} className={classes.gridItem}>
            <Paper elevation={4} className={classes.paper}>
              <OrderChangeFieldSettingsTable
                fieldSettings={fieldSettings}
                filterOptionsByCountry={filterOptionsByCountry(countryCode)}
                configOptions={configOptions}
                handleAddField={handleAddField}
                handleDeleteField={handleDeleteField}
                handleFieldChange={handleFieldChange}
              />
              <Box className={classes.saveButtonBox}>
                <LoadingButton
                  startIcon={<ArrowForward fontSize="small" />}
                  variant="outlined"
                  color="primary"
                  style={{background: 'rgb(1, 106, 212', textTransform: 'none'}}
                  onClick={onSaveButtonClick}
                  loading={isSubmitting}
                  data-test-id="order-change-configuration-save-button"
                >
                  {mode === 'edit'
                    ? t('configurations.hconnect.orderChange.configurationDetails.buttonSave')
                    : t(
                        `configurations.hconnect.orderChange.configurationDetails.button${
                          mode.charAt(0).toUpperCase() + mode.slice(1)
                        }`
                      )}
                </LoadingButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Page>
    </Content>
  )
}
