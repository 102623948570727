import {Content, Page, ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {TableFilterItemProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/Item/TableFilterItem.types'
import {TableFilterType} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.types'
import {Add} from '@mui/icons-material'
import {Box, Button, Grid, Paper} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {useQueryParamState} from '../../../common/QueryParamStateHook'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {useStyles} from '../Audiences/Audiences.styles'

import {Message, useMessages} from './hooks/useMessages'
import {useMessagesFilter} from './hooks/useMessagesFilter'
import {MessageListDetails} from './MessageListDetails'
import {MessagesListRows} from './MessagesListRows'

export type MessagesFilter = {
  rowsPerPage: number
  page: number
  sortColumn: string
  sortDirection: 'asc' | 'desc'
  subject: string
  audienceName: string
  sentDate: string
  body: string
  type: 'email' | 'sms'
  attachments?: boolean
}
export const MessagesList = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const history = useHistory()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const loggedInUserId = loggedInUserProfile?.user_id
  const [messageDetails, setMessageDetails] = useState<Message>()
  const [filter, setFilter] = useQueryParamState<MessagesFilter>({
    rowsPerPage: 10,
    page: 1,
    sortColumn: 'sentDate',
    sortDirection: 'desc'
  })
  const {
    data: messagesData,
    isLoading: isLoadingMessages,
    error: messagesError
  } = useMessages(filter)
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortColumn === sortKey && filter.sortDirection === 'asc')
    setFilter({
      ...filter,
      sortColumn: sortKey,
      sortDirection: asc ? 'asc' : 'desc'
    })
  }

  const {filterInputValues, setFilterInputValues, handleFilterInputValues, setRemovedFilter} =
    useMessagesFilter(filter, setFilter)

  const formatErrorMessage = () => {
    if (!isLoadingMessages) {
      if (messagesError?.response?.status === 404) {
        return t('audiences.noResults')
      } else if (messagesError) {
        return t('audiences.error')
      }
    }
  }

  const totalPageCount = messagesData?.total || 0
  return (
    <Content>
      <Page
        data-test-id="page-audiences"
        title={t('messages.title')}
        boxed={false}
        headerActionContent={
          <Box style={{display: 'flex', gap: '12px'}}>
            <Button
              variant="contained"
              data-test-id="create-audience-button"
              onClick={() => {
                history.push('/createAudience')
              }}
              style={{
                background: 'rgba(255, 255, 255, 0.05)',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.15)',
                marginTop: isMobile ? '12px' : 0
              }}
            >
              <Add className={classes.addIcon} fontSize="small" />
              {t('audiences.createAudience')}
            </Button>
            <Button
              variant="contained"
              data-test-id="create-message-button"
              onClick={() => {
                history.push('/messages/create', {redirect: 'messages'})
              }}
              style={{
                background: 'rgba(255, 255, 255, 0.05)',
                fontWeight: 500,
                border: '1px solid rgba(255, 255, 255, 0.15)',
                marginTop: isMobile ? '12px' : 0
              }}
            >
              <Add className={classes.addIcon} fontSize="small" />
              {t('messages.createMessage')}
            </Button>
          </Box>
        }
        classNameHeader={classes.pageHeader}
        {...(isMobile ? {px: 2, py: 2} : {py: 2})}
      >
        <Grid
          container
          style={{columnGap: '16px', marginTop: '22px', alignItems: 'stretch', minHeight: '50vh'}}
          wrap="nowrap"
        >
          <Grid
            item
            md={messageDetails && !isMobile ? 8 : 12}
            sm={12}
            xs={12}
            style={{height: '100%'}}
          >
            <Paper elevation={4} style={{padding: isMobile ? '4px 8px' : '26px 36px'}}>
              <ResponsiveTable
                keyField="id"
                columns={MessagesListRows()}
                rows={messagesData?.messages || []}
                onSort={(e, sortKey) => setSortKey(sortKey)}
                sortedBy={filter.sortColumn}
                sortingOrder={filter.sortDirection}
                emptyMessage={formatErrorMessage()}
                loading={isLoadingMessages}
                isMobile={isMobile}
                tableHeight="60vh"
                mobileRowDetails={
                  messageDetails && (
                    <MessageListDetails
                      messageDetails={messageDetails}
                      setMessageDetails={setMessageDetails}
                    />
                  )
                }
                onRowClick={(message) => {
                  if (message.id === messageDetails?.id) {
                    setMessageDetails?.(undefined)
                  } else {
                    setMessageDetails?.(message)
                  }
                }}
                page={filter.page}
                rowsPerPage={filter.rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                count={totalPageCount}
                enableHorizontalScroll={true}
                enableColumnSelection={true}
                rowDetailsAvailable={true}
                filterListInputValues={filterInputValues}
                setFilterListInputValues={setFilterInputValues}
                onFilterInputChange={(props: TableFilterItemProps) =>
                  handleFilterInputValues(props)
                }
                onFilterRemove={(filter: TableFilterType) => {
                  setRemovedFilter(filter)
                  setFilterInputValues(
                    filterInputValues.filter((inputValue) => {
                      return inputValue.filterField !== filter.filterField
                    })
                  )
                }}
                storeFiltersOptions={
                  loggedInUserId ? {userId: loggedInUserId, tableName: 'messages'} : undefined
                }
                stickyHeader={true}
                enablePinning={true}
              />
            </Paper>
          </Grid>
          {!isMobile && messageDetails ? (
            <Grid item md={4} sm={0} xs={0}>
              <Paper elevation={4} style={{padding: '26px 32px 26px 32px', height: '100%'}}>
                <MessageListDetails
                  messageDetails={messageDetails}
                  setMessageDetails={setMessageDetails}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </Page>
    </Content>
  )
}
