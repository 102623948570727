import {TableColumnType, useBreakPoints, useTranslation} from '@hconnect/uikit'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Chip, IconButton, Tooltip} from '@mui/material'
import classNames from 'classnames'
import {upperFirst} from 'lodash'
import moment from 'moment'
import React from 'react'

import {RTEInput, stripHtmlTags} from '../components/RTEInput'

import {Message} from './hooks/useMessages'
import {useStyles} from './MessageListRows.styles'

export const MessagesListRows = () => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isExtraLarge = ['xl'].includes(screenSizes)
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {classes} = useStyles()

  const MessageBody = (body: string): JSX.Element => {
    const displayBody =
      stripHtmlTags(body, []).length > 25 ? `${stripHtmlTags(body, []).substring(0, 25)} ...` : body
    const shouldShowTooltip = body.length > 25
    const richPreview = (trimBody: boolean) => (
      <RTEInput readOnly={true} value={trimBody ? displayBody : body} />
    )

    return (
      <Tooltip
        title={richPreview(false)}
        arrow
        placement="bottom"
        disableHoverListener={!shouldShowTooltip}
      >
        <Box style={{fontSize: '14px'}}>{richPreview(true)}</Box>
      </Tooltip>
    )
  }

  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'subject',
      mobileRowHeader: true,
      sortable: true,
      filterable: true,
      width: 250,
      headerName: t('messages.subject'),
      headerAlign: 'left',
      renderCell: (message: Message) => (
        <Box style={{fontSize: '14px'}}>{message.subject?.trim() ? message.subject : '-'}</Box>
      )
    },
    body: {
      field: 'body',
      mobileRowHeader: true,
      sortable: true,
      filterable: true,
      width: 250,
      headerName: t('messages.body'),
      headerAlign: 'left',
      renderCell: (message: Message) => <>{MessageBody(message.body)}</>
    },
    channel: {
      field: 'type',
      mobileRowHeader: true,
      sortable: true,
      filterable: true,
      width: 100,
      headerName: t('messages.channel'),
      headerAlign: 'left',
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: t('createMessage.form.channelEmail'), value: 'email'},
        {label: t('createMessage.form.channelSms'), value: 'sms'}
      ],
      filterShowSearch: false,
      renderCell: (message: Message) => (
        <Box style={{fontSize: '14px'}}>
          <Chip
            classes={{
              root: classNames({
                [classes.tags]: true,
                [classes.tagMod1]: true
              })
            }}
            key={message.type}
            label={upperFirst(message.type)}
            size="small"
          />
        </Box>
      )
    },
    sent: {
      field: 'sentDate',
      headerName: t('messages.sent'),
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DATEPICKER,
      renderCell: (message: Message) => (
        <Box style={{fontSize: '14px'}}>{moment(message.sentDate).format('DD/MM/YYYY')}</Box>
      )
    },
    createdBy: {
      field: 'createdBy',
      headerName: t('messages.author'),
      headerAlign: 'left',
      renderCell: (message: Message) => {
        return <Box style={{fontSize: '14px'}}>{message.createdBy}</Box>
      }
    },
    audience: {
      field: 'audienceName',
      headerName: t('messages.audience'),
      headerAlign: 'left',
      filterable: true,
      renderCell: (message: Message) => {
        return (
          <Box style={{fontSize: '14px'}}>
            {message.audiences.map((audience) => audience.name)?.join(' ,')}
          </Box>
        )
      }
    },
    actions: {
      field: 'actions',
      headerName: isMobile ? '' : t('messages.actions'),
      headerAlign: 'left',
      renderCell: (message: Message) => {
        return (
          <IconButton
            style={{boxShadow: 'none'}}
            onClick={() => {}}
            data-test-id={`action-edit-${message.subject}`}
          >
            <ModeEditOutlineIcon style={{color: '#016AD4'}} />
          </IconButton>
        )
      }
    },
    attachments: {
      field: 'attachments',
      headerName: t('messages.attachments.title'),
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: t('messages.attachments.hasAttachments'), value: 'true'},
        {label: t('messages.attachments.hasNoAttachments'), value: 'false'}
      ],
      renderCell: (message: Message) => {
        return (
          <Box style={{fontSize: '14px'}}>
            {message.attachments && message.attachments?.length > 0
              ? t('messages.attachments.hasAttachments')
              : t('messages.attachments.hasNoAttachments')}
          </Box>
        )
      }
    },
    expander: {
      field: 'expander',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100vw'}} />
      }
    }
  }

  const rowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.name,
    rowsPool.body,
    rowsPool.channel,
    rowsPool.sent,
    rowsPool.createdBy,
    rowsPool.audience,
    rowsPool.attachments,
    ...(isExtraLarge ? [rowsPool.expander] : [])
  ]
  return rowOrder(rowsPool)
}
