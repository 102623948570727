import {User} from '@hconnect/apiclient'
import {Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../../modules/LoggedInUser.selectors'
import {ResetPasswordDialog} from '../../ManageUser/dialogs/ResetPasswordDialog'
import {useResetPassword} from '../../ManageUser/hooks/useResetPassword'

interface ResetPasswordProps {
  user?: User
}

export const ResetPassword = ({user}: ResetPasswordProps) => {
  const {t} = useTranslation()

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const permissions = useSelector(selectLoggedInUserPermissions)

  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)

  const {handleResetPassword} = useResetPassword({
    setShowResetPasswordDialog,
    userData: user,
    isCsc: true
  })

  const canResetPassword =
    loggedInUserProfile?.id === user?.id ||
    permissions.some((permission) => permission.permissionType === 'CHANGE_USER_LOGIN')

  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setShowResetPasswordDialog(true)}
        data-test-id="button-reset-password"
        disabled={!canResetPassword}
        style={{
          width: '100%',
          color: '#29333D',
          fontWeight: 500,
          border: '1px solid #E8ECF0',
          borderRadius: '4px'
        }}
      >
        {t('quickPreview.resetPassword')}
      </Button>

      {user && (
        <ResetPasswordDialog
          showResetPasswordDialog={showResetPasswordDialog}
          setShowResetPasswordDialog={setShowResetPasswordDialog}
          handleResetPassword={handleResetPassword}
        />
      )}
    </Box>
  )
}
