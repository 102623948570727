import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Button} from '@material-ui/core'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {api} from '../../../App.store'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'

type UseResetPasswordProps = {
  setShowResetPasswordDialog: (showDialog: boolean) => void
  userData?: User
  isCsc?: boolean
}

export const useResetPassword = ({
  setShowResetPasswordDialog,
  userData,
  isCsc
}: UseResetPasswordProps) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const handleResetPassword = async () => {
    try {
      if (userData) {
        setShowResetPasswordDialog(false)
        await api.post('/users/resetPassword', {
          username: userData.username
        })

        trackEvent(isCsc ? 'cscQuickPreviewResetPasswordSuccess' : 'resetPasswordSuccess', {
          userId: loggedInUserProfile?.user_id,
          product: 'adminconsole',
          previewedUserId: userData.user_id
        })
        enqueueSnackbar(t('manageUser.resetPasswordSnackbarSuccess'), {
          // eslint-disable-next-line react/display-name
          action: () => (
            <Button onClick={() => closeSnackbar()} variant="text" color="primary">
              {t('manageUser.resetPasswordSnackbarClose')}
            </Button>
          ),
          variant: 'success'
        })
      }
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      trackEvent(isCsc ? 'cscQuickPreviewResetPasswordError' : 'resetPasswordError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: error.response?.status,
        component: 'ManageUser.tsx Reset password',
        endpoint: error.response?.config?.url
      })
      enqueueSnackbar(
        error.response?.status === 400 || error.response?.status === 403
          ? t('manageUser.hasNoPermissions')
          : t('manageUser.resetPasswordSnackbarError'),
        {variant: 'error'}
      )
    }
  }

  return {handleResetPassword}
}
