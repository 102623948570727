import {CreationChannel, Product, User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {LoadingButton, Typography} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useEffect, useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {isProd} from '../../common/Constants'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {RoleAssignment, RoleType} from '../../modules/ManageUsers.selectors'

import {WidgetBusinessLineInput} from './components/WidgetBusinessLineInput'
import {WidgetCountryInput} from './components/WidgetCountryInput'
import {WidgetCustomerInput} from './components/WidgetCustomerInput'
import {WidgetEmailInput} from './components/WidgetEmailInput'
import {WidgetMobileNumberInput} from './components/WidgetMobileNumberInput'
import {WidgetNameInput} from './components/WidgetNameInput'
import {WidgetOrgUnitInput} from './components/WidgetOrgUnitInput'
import {WidgetPermissionInput} from './components/WidgetPermissionInput'
import {WidgetProductInput} from './components/WidgetProductInput'
import {InviteUserPayload, useCreateWidgetUser} from './hooks/useCreateWidgetUser'
import {customerRoleSetup} from './utils/CustomerRoleSetup'
import {useWidgetUserCreationStyles} from './WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions,
  DataScopeOption
} from './WidgetUserCreation.types'

export type WidgetUserCreationFormData = {
  name: string
  email: string
  mobileNumber: string
  customerIds: CustomerData[]
  businessLine: string
  country: string
  orgUnit: string
  products: Product[]
  permissions: CustomerToggleBasedPermissions
}

type CustomerData = {
  customerId: string
  siteIds: string[]
}

export const WidgetUserCreationForm = () => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const history = useHistory()

  const [cscData, setCscData] = useState<RoleAssignment[]>()
  const cscCountries = cscData
    ?.map((data) => data.dataScope['countryId'])
    .filter((country, index, countries) => countries.indexOf(country) === index) as
    | string[]
    | undefined

  const cscBusinessLines = cscData
    ?.map((data) => data.dataScope['businessLine'])
    .filter(
      (businessLine, index, businessLines) => businessLines.indexOf(businessLine) === index
    ) as string[] | undefined
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>()
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | undefined>()

  const [selectedOrgUnit, setSelectedOrgUnit] = useState<DataScopeOption>()
  const [selectedProducts, setSelectedProducts] = useState<Product[]>()

  const initPermissions = {
    [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]: true,
    [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]: false,
    [CustomerToggleBasedPermissionKeys.canSeeInvoices]: false
  }

  const formMethods = useForm<WidgetUserCreationFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      mobileNumber: '',
      customerIds: [{customerId: '', siteIds: []}],
      orgUnit: '',
      businessLine: '',
      country: '',
      products: [],
      permissions: initPermissions
    }
  })

  const {fields, append, remove} = useFieldArray({
    name: 'customerIds' as const,
    control: formMethods.control
  })

  const {
    handleCreateUser,
    isLoading: isLoadingCreateUser,
    isUserCreated
  } = useCreateWidgetUser(formMethods)

  const {data: roles} = useUserRoles(loggedInUserProfile?.user_id)

  useEffect(() => {
    if (roles && roles?.length > 0) {
      const cscRoles = roles?.filter(
        (role) => role.roleType === 'CUSTOMER_SERVICE_CENTER' || role.roleType === 'SALES_AGENT'
      )
      setCscData(cscRoles)
    }
  }, [roles])

  useEffect(() => {
    if (selectedCustomerId && !selectedCustomerNumber) {
      const customerIndex = formMethods
        .watch('customerIds')
        .findIndex((customerData) => customerData.customerId === selectedCustomerId)
      formMethods.resetField(`customerIds.${customerIndex}`, {
        defaultValue: {customerId: '', siteIds: []}
      })
      setSelectedCustomerId(undefined)
    }
  }, [selectedCustomerNumber])

  useEffect(() => {
    if (selectedOrgUnit && selectedCustomerNumber) {
      const customerIndex = formMethods
        .watch('customerIds')
        .findIndex((customerData) => customerData.customerId === selectedCustomerId)

      formMethods.resetField(`customerIds.${customerIndex}`, {
        defaultValue: {customerId: '', siteIds: []}
      })

      setSelectedCustomerNumber(undefined)
    }
  }, [selectedOrgUnit])

  useEffect(() => {
    if (selectedProducts !== undefined) {
      formMethods.setValue('products', selectedProducts)
      if (selectedProducts.find((product) => product === Product.Hub)) {
        void formMethods.trigger('email')
        void formMethods.clearErrors('mobileNumber')
      }

      if (selectedProducts.find((product) => product === Product.OnSite)) {
        void formMethods.trigger('mobileNumber')
        void formMethods.clearErrors('email')
      }

      if (selectedProducts.length === 0) {
        void formMethods.trigger('email')
        void formMethods.trigger('mobileNumber')
      }
    }
  }, [selectedProducts])

  const products = formMethods.watch('products')
  const selectedCountry = formMethods.watch('country')
  const selectedBusinessLine = formMethods.watch('businessLine')
  const handleSubmitForm = async (data: WidgetUserCreationFormData) => {
    const userData: Partial<User> = {
      name: data.name,
      eMail: data.email,
      mobileNumber: data.mobileNumber,
      country: cscCountries && cscCountries?.length > 1 ? data.country : cscCountries?.[0] || '',
      creationProduct: 'HConnect',
      isTermsApprovalRequired: true,
      creationChannel: 'portalRegistration' as CreationChannel,
      isTester: !isProd
    }

    const assignUserRolesData: Omit<RoleAssignment, 'userId'>[] = customerRoleSetup(
      data.permissions
    ).flatMap((roleType) => {
      const areSiteIdsPresent =
        data.customerIds.filter((customerId) => customerId.siteIds.length > 0).length > 0

      return areSiteIdsPresent
        ? data.customerIds.map((customerId) => ({
            id: -1,
            roleType: roleType as RoleType,
            dataScope: {
              countryId:
                cscCountries && cscCountries?.length > 1 ? data.country : cscCountries?.[0] || '',
              businessLine:
                cscBusinessLines && cscBusinessLines?.length > 1
                  ? data.businessLine
                  : cscBusinessLines?.[0] || '',
              orgUnitId: selectedOrgUnit?.value || '',
              customerIds: [customerId.customerId],
              siteIds: customerId.siteIds
            }
          }))
        : {
            id: -1,
            roleType: roleType as RoleType,
            dataScope: {
              countryId:
                cscCountries && cscCountries?.length > 1 ? data.country : cscCountries?.[0] || '',
              businessLine:
                cscBusinessLines && cscBusinessLines?.length > 1
                  ? data.businessLine
                  : cscBusinessLines?.[0] || '',
              orgUnitId: selectedOrgUnit?.value || '',
              customerIds: data.customerIds.map((customerData) => customerData.customerId) || ['']
            }
          }
    })

    const inviteUserData: Omit<InviteUserPayload, 'user_id'>[] = data.products.map((product) => ({
      product
    }))

    trackEvent('cscWidgetUserCreationFormSubmit', {
      userId: loggedInUserProfile?.user_id,
      product: 'adminconsole',
      createUserData: JSON.stringify(userData),
      assignUserRolesData: JSON.stringify(assignUserRolesData),
      inviteUserData: JSON.stringify(inviteUserData)
    })

    await handleCreateUser({
      createUser: userData,
      assignUserRoles: assignUserRolesData,
      inviteUser: inviteUserData
    })
  }

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmitForm)}>
      <WidgetNameInput formMethods={formMethods} />
      <WidgetEmailInput formMethods={formMethods} selectedProducts={selectedProducts} />
      <WidgetMobileNumberInput formMethods={formMethods} selectedProducts={selectedProducts} />
      {cscCountries && cscCountries?.length > 1 && (
        <WidgetCountryInput formMethods={formMethods} countryCodes={cscCountries} />
      )}
      {cscBusinessLines && cscBusinessLines?.length > 1 && (
        <WidgetBusinessLineInput
          formMethods={formMethods}
          disabled={cscCountries && cscCountries?.length > 1 ? !selectedCountry : false}
          cscBusinessLines={cscBusinessLines}
        />
      )}
      <WidgetOrgUnitInput
        formMethods={formMethods}
        cscData={cscData}
        selectedOrgUnit={selectedOrgUnit}
        setSelectedOrgUnit={setSelectedOrgUnit}
        disabled={cscBusinessLines && cscBusinessLines?.length > 1 ? !selectedBusinessLine : false}
      />
      <Box style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        {fields.map((field, index) => (
          <Box key={field.id} style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <WidgetCustomerInput
              key={field.id}
              index={index}
              formMethods={formMethods}
              selectedOrgUnit={selectedOrgUnit}
              cscData={cscData}
              remove={remove}
            />
          </Box>
        ))}
      </Box>
      <Button
        startIcon={<Add style={{color: '#00374d', fontSize: '16px'}} />}
        className={classes.verifyButton}
        style={{alignSelf: 'start', marginTop: '12px'}}
        onClick={() => append({customerId: '', siteIds: []})}
      >
        Add Customer ID
      </Button>
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: '24px', marginTop: '48px'}}
      >
        {t('widgetUserCreation.form.products.title')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column'}}>
        <WidgetProductInput
          product={Product.Hub}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <WidgetProductInput
          product={Product.OnSite}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      </Box>
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: '24px', marginTop: '48px'}}
      >
        {t('widgetUserCreation.form.permissions')}
      </Typography>
      <Box style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '24px'
          }}
        >
          {Object.keys(initPermissions).map((key) => (
            <Controller
              key={key}
              name={`permissions.${key as keyof typeof CustomerToggleBasedPermissionKeys}`}
              control={formMethods.control}
              render={({field}) => (
                <WidgetPermissionInput
                  permissionKey={key}
                  checked={!!formMethods.watch('permissions')[key]}
                  onChange={(checked) => field.onChange(checked)}
                  disabled={
                    !formMethods.formState.dirtyFields.permissions?.[key] &&
                    !!formMethods.watch('permissions')[key]
                  }
                />
              )}
            />
          ))}
        </Box>
      </Box>
      <Box
        style={{display: 'flex', gap: '12px', justifyContent: 'space-between', marginTop: '48px'}}
      >
        <Button
          className={classes.discardButton}
          variant="text"
          onClick={() => {
            trackEvent('cscWidgetUserCreationGoToSearch', {
              userId: loggedInUserProfile?.user_id,
              product: 'adminconsole'
            })
            history.push('/widgetUserList')
          }}
        >
          {t('widgetUserCreation.form.goBackToSearch')}
        </Button>
        {!isUserCreated && (
          <LoadingButton
            type="submit"
            btnClassName={classes.submitButton}
            progressClassName={classes.progress}
            loading={isLoadingCreateUser}
            disabled={
              isEmpty(formMethods.formState.dirtyFields) ||
              !isEmpty(formMethods.formState.errors) ||
              !formMethods.formState.isValid ||
              products.length === 0
            }
          >
            <Typography
              style={{
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 500,
                letterSpacing: 0,
                color: '#FFFFFF'
              }}
            >
              {t('widgetUserCreation.form.create')}
            </Typography>
          </LoadingButton>
        )}
      </Box>
    </form>
  )
}
