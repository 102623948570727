import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Button} from '@mui/material'
import {QueryObserverResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {FieldValues, Path, UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {UserInformationFormData} from '../../../components/UserInformation/UserInformationForm.types'
import {handleValidationError} from '../../../errorHandling'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {updateUser} from '../../../modules/Users.actions'

type UseUpdateUserProps<T extends FieldValues> = {
  setEditing?: (edit: boolean) => void
  formMethods: UseFormReturn<T>
  refetchUser: () => Promise<QueryObserverResult<User | undefined>>
  userData?: User
  emailFieldName: Path<T>
  mobileNumberFieldName: Path<T>
  isCsc?: boolean
}

export const useUpdateUser = <T extends FieldValues>({
  setEditing,
  formMethods,
  userData,
  refetchUser,
  emailFieldName,
  mobileNumberFieldName,
  isCsc
}: UseUpdateUserProps<T>) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t, i18n} = useTranslation()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const [isUpdateUserSuccess, setIsUpdateUserSuccess] = useState(false)
  const handleUpdateUser = async (userInformation: Partial<UserInformationFormData>) => {
    setIsUpdateUserSuccess(false)
    if (userData) {
      try {
        // don't send availableCountries in updateUser
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {availableCountries, marketId, ...values} = userInformation

        const formattedValues =
          (values.country === 'CA' || values.country === 'US') && marketId === 'hproduce'
            ? {...values, marketId: ''}
            : {...values, marketId}
        // eslint-disable-line @typescript-eslint/no-unused-vars
        await updateUser(userData.id, formattedValues)

        enqueueSnackbar(t('manageUser.userWasUpdated'), {
          variant: 'success',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        })
        await refetchUser()
        setEditing && setEditing(false)

        if (loggedInUserProfile?.id === userData.id) {
          void i18n.changeLanguage(values.defaultLocale)
        }
        trackEvent(isCsc ? 'cscQuickPreviewUpdateUserSuccess' : 'updateUserSuccess', {
          userId: loggedInUserProfile?.user_id,
          previewedUserId: userData.id,
          product: 'adminConsole'
        })
        setIsUpdateUserSuccess(true)
      } catch (e) {
        const error = e as AxiosError
        setIsUpdateUserSuccess(false)
        trackEvent(isCsc ? 'cscQuickPreviewUpdateUserError' : 'updateUserError', {
          product: 'adminConsole',
          date: new Date().toISOString(),
          errorCode: error.response?.status,
          component: 'ManageUser.tsx updateUser',
          endpoint: error.response?.config?.url
        })
        const errorMessage = handleValidationError(
          error,
          formMethods,
          emailFieldName,
          mobileNumberFieldName
        )

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        })
      }
    }
  }

  return {handleUpdateUser, isUpdateUserSuccess}
}
