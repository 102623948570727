import {Typography} from '@hconnect/uikit'
import clsx from 'clsx'
import React from 'react'

import {useStyles} from './CompanyName.styles'

type CompanyNameProps = {
  isCloneable: boolean
  customerName?: string | undefined
  ellipsis?: boolean
}
export const CompanyName = ({isCloneable, customerName, ellipsis}: CompanyNameProps) => {
  const {classes} = useStyles()
  return (
    <Typography
      style={{
        opacity: isCloneable ? 1 : 0.5,
        textAlign: 'left'
      }}
      className={clsx({[classes.ellipsis]: ellipsis})}
    >
      {customerName}
    </Typography>
  )
}
