import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Typography} from '@hconnect/uikit'
import {ArrowBackIos} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {useQueryParamState} from '../../common/QueryParamStateHook'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {useUsers} from '../UsersList/hooks/useUsers'
import {UsersTableFilter} from '../UsersList/UsersList.types'

import {ListView, ListViewItem} from './components/ListView'
import {WidgetUserListDetailInput} from './components/WidgetUserListDetailInput'
import {WidgetUserListForm} from './WidgetUserList.form'
import {useStyles} from './WidgetUserList.styles'

export const WidgetUserList = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {classes} = useStyles()

  const [filter, setFilter] = useQueryParamState<UsersTableFilter>({
    rowsPerPage: 10,
    page: 1,
    sortColumn: 'createdOn',
    sortDirection: 'desc'
  })
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const {data: users, isInitialLoading: usersLoading} = useUsers(filter, true)

  const [isSearchForm, setIsSearchForm] = useState(window.location.search.length < 1)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setFilter({...filter, page: newPage})
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, rowsPerPage: parseInt(event.target.value, 10), page: 1})
  }

  const totalPageCount = users?.headers ? parseInt(users.headers['x-total-count'], 10) : 0

  const rowsPerPage = Number(filter.rowsPerPage)

  const mappedUsers: ListViewItem[] =
    users?.data.map((user) => ({
      id: user.user_id,
      title: user.name ? user.name : user.eMail || user.mobileNumber,
      subtitle: (
        <Box style={{display: 'flex', flexDirection: 'column', marginTop: '6px'}}>
          {user.name && user.eMail && (
            <WidgetUserListDetailInput label="Email:" value={user.eMail} />
          )}
          {(user.name || user.eMail) && user.mobileNumber && (
            <WidgetUserListDetailInput label="Phone:" value={user.mobileNumber} />
          )}
          {user.companyName && (
            <WidgetUserListDetailInput label="Company:" value={user.companyName} />
          )}
        </Box>
      )
    })) || []

  return (
    <Box style={{width: '100%', padding: '14px'}}>
      {!isSearchForm && (
        <Button
          variant="text"
          style={{
            color: '#007837',
            fontSize: '14px',
            fontWeight: 500,
            marginLeft: '-8px',
            padding: '4px 16px'
          }}
          startIcon={<ArrowBackIos style={{color: '#007837', fontSize: '12px'}} />}
          onClick={() => setIsSearchForm(true)}
        >
          {t('widgetUserList.goBack')}
        </Button>
      )}
      <Typography style={{fontSize: '28px', fontWeight: 700, margin: '0 0 24px 0'}}>
        {t('widgetUserList.title')}
      </Typography>
      <Typography
        style={{fontSize: '18px', fontWeight: 600, marginBottom: isSearchForm ? '24px' : 0}}
      >
        {isSearchForm ? t('widgetUserList.form.title') : t('widgetUserList.usersList.title')}
      </Typography>
      {isSearchForm && (
        <WidgetUserListForm
          setIsSearchForm={setIsSearchForm}
          filter={filter}
          setFilter={setFilter}
        />
      )}
      {!isSearchForm && (
        <Box style={{marginTop: mappedUsers.length === 0 ? '32px' : 0}}>
          <ListView
            items={mappedUsers}
            onClick={(item) => history.push(`/manage/quick-preview/${item.id}`, {isUserList: true})}
            isLoading={usersLoading}
            noResultsMessage={t('widgetUserList.usersList.noResults')}
            paginationOptions={{
              count: totalPageCount,
              page: filter.page,
              rowsPerPage: rowsPerPage,
              onChangeRowsPerPage: handleChangeRowsPerPage,
              onChangePage: handleChangePage
            }}
          />
        </Box>
      )}
      {!isSearchForm && mappedUsers.length === 0 && (
        <Button
          variant="outlined"
          style={{width: '100%', marginTop: '32px'}}
          className={clsx(classes.createUserButton, classes.createUserButtonFilled)}
          onClick={() => {
            trackEvent('cscUserListNoResultsGoToCreateUser', {
              userId: loggedInUserProfile?.user_id,
              product: 'adminconsole'
            })
            history.push('/widgetUserCreation')
          }}
          data-test-id="widget-user-create-button"
        >
          {t('widgetUserList.form.createUser')}
        </Button>
      )}
    </Box>
  )
}
