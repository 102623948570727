import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Button} from '@mui/material'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'

import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {assignUserRole} from '../../WidgetUserCreation/hooks/useCreateWidgetUser'

const assignUserRoles = async (roles: RoleAssignment[]) => {
  for (const userRole of roles) {
    await assignUserRole(userRole)
  }
}
export const useCreateUserRoles = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const {userId} = useParams<{userId: string}>()

  const queryClient = useQueryClient()

  return useMutation(assignUserRoles, {
    onSuccess: () => {
      trackEvent('cscQuickPreviewCreateUserRolesSuccess', {
        userId: loggedInUserProfile?.user_id,
        product: 'adminConsole',
        previewedUserId: userId
      })
      void queryClient.invalidateQueries(['roles'])
    },
    onError: (e) => {
      const error = e as AxiosError
      trackEvent('cscQuickPreviewCreateUserRolesError', {
        userId: loggedInUserProfile?.user_id,
        product: 'adminConsole',
        previewedUserId: userId,
        error: JSON.stringify(error)
      })
      enqueueSnackbar(
        error.response?.status === 304
          ? t('quickPreview.addPermissions.form.errorRoleExistsMsg')
          : (error.response?.data as any)?.message || t('errorboundary.error'),
        {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'error',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        }
      )
    }
  })
}
