import {Country, Product, User} from '@hconnect/apiclient'
import {TableColumnType, useMobileBreakPoint, useTranslation} from '@hconnect/uikit'
import {TableFilterTypeProps} from '@hconnect/uikit/src/lib/Molecules/Table/Filter/TableFilter.enum'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import {Box, Button, Tooltip, Typography, Chip, Link} from '@mui/material'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {useAdminPilotUser} from '../../common/hooks/useAdminPilotUser'
import {RoleConfiguration} from '../../common/hooks/useRolesConfiguration'
import {countryDataByCountryCode} from '../../common/utils/utils'
import {
  getBrandingNameFromBrandingId,
  getMarketLabel,
  issuesForUser
} from '../../components/UserInformation/utils/utils'
import {prettyRoleName} from '../../layouts/Roles/utils'

import {RoleOption, UserIssueKeys} from './UsersList.types'
import {useStyles} from './UsersListRows.styles'

type UsersListRowsProps = {
  isExternalUser: boolean
  countries?: Country[]
  roles?: RoleConfiguration[]
  loadCrossBorderUsers?: boolean
  isCreateAudience?: boolean
}
export const UsersListRows = ({
  countries,
  roles,
  isExternalUser,
  isCreateAudience
}: UsersListRowsProps) => {
  const {t, i18n} = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const isUserAdminPilot = useAdminPilotUser()
  const isMobile = useMobileBreakPoint()
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const roleOptions: RoleOption[] =
    roles?.map(({roleType}) => ({
      label: t(`roleAssignment.roleTypes.${roleType}`, prettyRoleName(roleType)),
      value: roleType
    })) || []
  const countryDataFilter = (countryId?: string | null) =>
    countryDataByCountryCode(countriesData, countryId)
  const languageFilter = (countryId?: string | null, defaultLocale?: string | null) =>
    countries
      ?.find((country) => country.countryCode === countryDataFilter(countryId)?.iso2.toUpperCase())
      ?.locales.find((locale) => locale.code === defaultLocale)?.name

  const rowsPool: {[key: string]: TableColumnType} = {
    name: {
      field: 'name',
      filterName: t('usersList.name'),
      mobileRowHeader: true,
      headerName: t('usersList.name'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      sortable: true,
      filterable: true,
      renderCell: (user: User) => (
        <Box
          style={{
            maxWidth: isMobile ? '400px' : '250px',
            minWidth: '150px',
            display: 'flex',
            flexDirection: isMobile ? 'row' : 'column',
            alignItems: isMobile ? 'center' : 'start'
          }}
        >
          <Box className={classes.textOverflow}>{user.name || '-'}</Box>
        </Box>
      )
    },
    companyName: {
      field: 'companyName',
      sortable: true,
      filterable: true,
      headerName: t('usersList.companyName'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (user: User) => <Box>{user.companyName || '-'}</Box>
    },
    eMail: {
      field: 'eMail',
      sortable: true,
      filterable: true,
      headerName: t('usersList.email'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (user: User) => {
        return <Box style={{wordBreak: isMobile ? 'break-all' : 'normal'}}>{user.eMail || '-'}</Box>
      }
    },
    mobileNumber: {
      field: 'mobileNumber',
      filterable: true,
      headerClassName: classes.columnHeader,
      headerName: t('usersList.mobileNumber'),
      headerAlign: 'left',
      renderCell: (user: User) => {
        return <Box style={{display: 'flex'}}>{user.mobileNumber || '-'}</Box>
      }
    },
    country: {
      field: 'country',
      sortable: true,
      headerName: t('usersList.country'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ? countries.map(({countryName, countryCode}) => ({
            label: countryDataFilter(countryCode)?.name ?? countryName,
            value: countryCode
          }))
        : [],
      renderCell: (user: User) => {
        return (
          <Box
            style={{
              display: 'flex'
            }}
          >
            {countryDataFilter(user.country)?.name || '-'}
          </Box>
        )
      }
    },
    createdOn: {
      field: 'createdOn',
      sortable: true,
      filterable: true,
      headerClassName: classes.columnHeader,
      filterType: TableFilterTypeProps.DATEPICKER,
      headerName: t('usersList.creationDate'),
      headerAlign: 'left',
      renderCell: (user: User) => {
        const createText =
          user.createdOn !== undefined && user.createdOn !== null
            ? new Date(user.createdOn).toLocaleDateString(i18n.language)
            : '-'
        return (
          <Box>
            <div className={classes.textOverflow}>{createText}</div>
          </Box>
        )
      }
    },
    creationChannel: {
      field: 'creationChannel',
      sortable: true,
      headerName: t('usersList.creationChannel'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {label: t('usersList.creationChip.automaticInvite'), value: 'automaticInvite'},
        {label: t('usersList.creationChip.employeeInvite'), value: 'employeeInvite'},
        {label: t('usersList.creationChip.inAppRegistration'), value: 'inAppRegistration'},
        {label: t('usersList.creationChip.inAuthRegistration'), value: 'inAuthRegistration'},
        {label: t('usersList.creationChip.portalRegistration'), value: 'portalRegistration'},
        {label: t('usersList.creationChip.unknown'), value: 'unknown'},
        {label: t('usersList.creationChip.userInvite'), value: 'userInvite'}
      ].sort((a, b) => (a.label > b.label ? 1 : -1)),
      renderCell: (user: User) => {
        return (
          <Box style={{display: 'flex'}}>
            {user.creationChannel ? (
              <Chip
                className={classes.chip}
                size="small"
                label={t(`usersList.creationChip.${user.creationChannel}`)}
              />
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },
    lastActivityDate: {
      field: 'lastActivityDate',
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DATEPICKER,
      headerName: t('usersList.lastActivityDate'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      renderCell: (user: User) => {
        const lastActivityDate = user.lastActivityDates?.[0]?.lastActivityDate

        if (isUserAdminPilot && (!user.lastLoginDate || user.lastLoginDate === '0001-01-01')) {
          return <Box>{t('usersList.issueTypes.failedLogin')}</Box>
        }

        return (
          <Box>
            {lastActivityDate ? (
              <Box>
                <div className={classes.textOverflow}>
                  {new Date(lastActivityDate).toLocaleDateString(i18n.language)}
                </div>
              </Box>
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },

    lastActivityProduct: {
      field: 'lastActivityProduct',
      sortable: true,
      headerName: t('usersList.lastActivityProduct'),
      headerClassName: classes.columnHeader,
      headerAlign: 'left',
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: Object.entries(Product).map(([key, value]) => ({
        label: key,
        value: value
      })),
      renderCell: (user: User) => {
        const lastActivityProduct = user.lastActivityDates?.[0]?.clientId

        if (isUserAdminPilot && (!user.lastLoginDate || user.lastLoginDate === '0001-01-01')) {
          return <Box>{t('usersList.issueTypes.failedLogin')}</Box>
        }

        return (
          <Box>
            {lastActivityProduct ? (
              <Box>
                {user.lastActivityDates?.[0].clientId && !isUserAdminPilot && (
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={user.lastActivityDates[0].clientId}
                  />
                )}
              </Box>
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },
    issues: {
      field: 'userIssue',
      headerName: t('usersList.issues'),
      headerClassName: classes.columnHeader,
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {
          value: UserIssueKeys.NO_PSW,
          label: t('usersList.issueTypes.userHasNoInvite')
        },
        {
          value: UserIssueKeys.NEVER_LOGGED_IN,
          label: t('usersList.issueTypes.neverLoggedIn')
        },
        {
          value: UserIssueKeys.HAS_ROLES,
          label: t('usersList.issueTypes.userHasNoRoles')
        },
        {
          value: UserIssueKeys.IS_LOCKED_OUT,
          label: t('usersList.issueTypes.isLockedOut')
        },
        {
          value: UserIssueKeys.FAILED_LOGIN,
          label: t('usersList.issueTypes.failedLogin')
        }
      ],
      flex: 6,
      renderCell: (user: User) => {
        const issues = issuesForUser(user)
        return (
          <Box>
            {issues.length > 0
              ? issues.map((issue) => (
                  <div className={classes.issue} key={issue}>
                    {t(`usersList.issueTypes.${issue}`)}
                  </div>
                ))
              : '-'}
          </Box>
        )
      }
    },
    language: {
      field: 'language',
      headerName: t('usersList.language'),
      headerClassName: classes.columnHeader,
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ?.map((country) => country.locales)
        .reduce((a, b) => a.concat(b), [])
        .map((locale) => ({label: locale.name, value: locale.code})),
      flex: 6,
      renderCell: (user: User) => {
        return <Box>{languageFilter(user.country, user.defaultLocale) || '-'}</Box>
      }
    },
    market: {
      field: 'marketId',
      headerName: t('usersList.market'),
      headerClassName: classes.columnHeader,
      sortable: true,
      filterable: true,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ?.map((country) => country.markets)
        .reduce((a, b) => a.concat(b), [])
        .filter(
          (market) =>
            market.products &&
            !market.products.includes('DispatchPhoneKey') &&
            !market.products.includes('Deprecated')
        )
        .map((market) => ({label: getMarketLabel(market), value: market.marketId}))
        .concat({label: t('usersList.blank'), value: 'blank'}),
      renderCell: (user: User) => {
        const userMarket = countries
          ?.find((country) => country.countryCode === user.country)
          ?.markets.find((market) => market.marketId === user?.marketId)
        const marketName = userMarket ? getMarketLabel(userMarket) : user?.marketId

        return <Box>{marketName || '-'}</Box>
      }
    },
    roles: {
      field: 'roleType',
      filterable: true,
      headerClassName: classes.columnHeader,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: roleOptions.sort((a, b) => (a.label > b.label ? 1 : -1)),
      headerName: t('usersList.hasRoles'),
      renderCell: (user: User) => {
        if (user.hasRoles.length > 3) {
          return (
            <Tooltip
              title={user.hasRoles.map((role) => t(`roleAssignment.roleTypes.${role}`)).join(', ')}
              arrow
              placement="bottom"
            >
              <Box
                display="flex"
                flexWrap="nowrap"
                whiteSpace={`${isMobile ? 'pre-wrap' : 'nowrap'}`}
              >
                <Typography variant="inherit" component="div">
                  {user.hasRoles
                    .slice(0, 2)
                    .map((role) => t(`roleAssignment.roleTypes.${role}`))
                    .join(', ')}
                  <Typography variant="inherit" component="div">
                    +{`${user.hasRoles.length - 2} more`}
                  </Typography>
                </Typography>
              </Box>
            </Tooltip>
          )
        }
        return (
          <Box display="flex" flexWrap="wrap">
            {user.hasRoles.length > 0 ? (
              <Typography variant="inherit" component="div">
                {user.hasRoles.map((role) => t(`roleAssignment.roleTypes.${role}`)).join(', ')}
              </Typography>
            ) : (
              '-'
            )}
          </Box>
        )
      }
    },
    branding: {
      field: 'branding',
      sortable: true,
      filterable: true,
      headerClassName: classes.columnHeader,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: countries
        ?.map((country) => country.brandings)
        .reduce((a, b) => a.concat(b), [])
        .map((branding) => ({
          label: getBrandingNameFromBrandingId(
            countries,
            branding.brandingId,
            branding.countryCode
          ),
          value: `${branding.countryCode}.${branding.brandingId}`
        })),
      headerName: t('usersList.branding'),
      renderCell: (user: User) => {
        const branding = getBrandingNameFromBrandingId(
          countries || [],
          user.defaultBranding || '',
          user.country || undefined
        )
        return <Box>{branding.length > 0 ? branding : '-'}</Box>
      }
    },
    isTester: {
      field: 'isTester',
      headerName: t('usersList.isTester'),
      headerClassName: classes.columnHeader,
      sortable: true,
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {
          value: 'true',
          label: t('usersList.show')
        },
        {
          value: 'false',
          label: t('usersList.hide')
        }
      ],
      renderCell: (user: User) => {
        return <Box>{t(`usersList.${user.isTester ? 'yes' : 'no'}`)}</Box>
      }
    },
    isInternal: {
      field: 'isInternal',
      headerClassName: classes.columnHeader,
      headerName: t('usersList.isInternal'),
      sortable: true,
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {
          value: 'true',
          label: t('usersList.show')
        },
        {
          value: 'false',
          label: t('usersList.hide')
        }
      ],
      renderCell: (user: User) => {
        return <Box>{t(`usersList.${user.isInternal ? 'yes' : 'no'}`)}</Box>
      }
    },
    blocklistNotifications: {
      uncheckedByDefault: true,
      field: 'blocklistNotifications',
      headerClassName: classes.columnHeader,
      headerName: t('usersList.blocklistNotifications'),
      sortable: true,
      filterable: true,
      filterShowSearch: false,
      filterType: TableFilterTypeProps.DROPDOWN,
      filterDropdownOptions: [
        {
          value: 'true',
          label: t('usersList.yes')
        },
        {
          value: 'false',
          label: t('usersList.no')
        }
      ],
      renderCell: (user: User) => {
        return <Box>{t(`usersList.${user.blocklistNotifications ? 'yes' : 'no'}`)}</Box>
      }
    },
    actions: {
      field: 'actions',
      headerClassName: classes.columnHeader,
      headerName: isMobile ? '' : t('usersList.actions'),
      headerAlign: 'left',
      renderCell: (user: User) => {
        const basePath = isUserAdminPilot ? `/managePilot/${user.id}` : `/manage/${user.id}`
        const query =
          new URLSearchParams(location.search).get('loadCrossBorderUsers') === 'true'
            ? '?includeCrossBorderUser=true'
            : ''
        const pushLink = `${basePath}${query}`
        return isMobile ? (
          <Button
            href={pushLink}
            className={classes.manageButton}
            onClick={(e: any) => {
              e.preventDefault()
              history.push(pushLink, {source: 'list', from: window.location.href})
            }}
          >
            <ModeEditOutlineIcon style={{marginRight: '4px'}} />
            {t('usersList.manageUser')}
          </Button>
        ) : (
          <Link
            color="primary"
            href={pushLink}
            style={{display: 'flex', width: '100%', justifyContent: 'center'}}
            onClick={(e: any) => {
              e.preventDefault()
              history.push(pushLink, {source: 'list', from: window.location.href})
            }}
          >
            <ModeEditOutlineIcon style={{marginRight: '4px'}} />
          </Link>
        )
      }
    },
    expander: {
      field: 'expander',
      width: '100%',
      noColumnSelection: true,
      renderCell: () => {
        return <Box style={{width: '100%'}} />
      }
    }
  }

  const internalMobileRowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.name,
    ...(!isCreateAudience ? [rowsPool.actions] : []),
    rowsPool.companyName,
    rowsPool.eMail,
    rowsPool.mobileNumber,
    rowsPool.country,
    rowsPool.createdOn,
    rowsPool.creationChannel,
    rowsPool.lastActivityDate,
    rowsPool.lastActivityProduct,
    rowsPool.issues,
    rowsPool.language,
    rowsPool.market,
    rowsPool.roles,
    rowsPool.branding,
    rowsPool.isTester,
    rowsPool.isInternal,
    rowsPool.blocklistNotifications
  ]

  const internalRowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    ...(!isCreateAudience ? [rowsPool.actions] : []),
    rowsPool.name,
    rowsPool.companyName,
    rowsPool.eMail,
    rowsPool.mobileNumber,
    rowsPool.country,
    rowsPool.createdOn,
    rowsPool.creationChannel,
    rowsPool.lastActivityDate,
    rowsPool.lastActivityProduct,
    rowsPool.issues,
    rowsPool.language,
    rowsPool.market,
    rowsPool.roles,
    rowsPool.branding,
    rowsPool.isTester,
    rowsPool.isInternal,
    rowsPool.blocklistNotifications,
    rowsPool.expander
  ]

  const externalMobileRowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    rowsPool.name,
    ...(!isCreateAudience ? [rowsPool.actions] : []),
    rowsPool.companyName,
    rowsPool.eMail,
    rowsPool.mobileNumber,
    rowsPool.createdOn,
    rowsPool.lastActivityDate,
    rowsPool.lastActivityProduct,
    rowsPool.issues,
    rowsPool.language,
    rowsPool.market,
    rowsPool.roles,
    rowsPool.blocklistNotifications,
    rowsPool.branding
  ]

  const externalRowOrder = (rowsPool: {[key: string]: TableColumnType}) => [
    ...(!isCreateAudience ? [rowsPool.actions] : []),
    rowsPool.name,
    rowsPool.companyName,
    rowsPool.eMail,
    rowsPool.mobileNumber,
    rowsPool.createdOn,
    rowsPool.lastActivityDate,
    rowsPool.lastActivityProduct,
    rowsPool.issues,
    rowsPool.language,
    rowsPool.market,
    rowsPool.roles,
    rowsPool.branding,
    rowsPool.blocklistNotifications,
    rowsPool.expander
  ]

  return isExternalUser
    ? (isMobile ? externalMobileRowOrder : externalRowOrder)(rowsPool)
    : (isMobile ? internalMobileRowOrder : internalRowOrder)(rowsPool)
}
